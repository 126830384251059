import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ){

  }

  canActivate(){
    if(this._authService.loggedIn()){
      return true
    }
    
      this._router.navigate(['/login'])
      return false
  }
  
}
